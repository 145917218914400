body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  background: linear-gradient(
      180deg,
      #060809 0%,
      rgba(59, 75, 83, 0.3) 49.48%,
      rgba(59, 75, 83, 0) 100%
    ),
    #060809;
  /* background-color: #060809; */
  color: #fff;
}
body,
html,
#root {
  height: 100%;
  position: relative;
}
canvas {
  position: relative;
}
#root:after,
#root:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 15%;
  background: linear-gradient(180deg, rgba(6, 8, 9, 0) 0%, #060809 100%);
  z-index: 1;
}
#root:after {
  top: 0;
  transform: rotate(180deg);
}
#root::before {
  bottom: 0;
}
.hero {
  color: #fff;
}
